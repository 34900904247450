import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  button: {
    bg: [`gray.75`, null, `transparent`],
    h: `auto`,
    p: `initial`,
    minWidth: `initial`,
    _hover: {
      bg: `gray.75`,
    },
    _disabled: {
      bg: `gray.75`,
      color: `black`,
    },
  },
  icon: {
    color: `black`,
  },
};

const sizes: SystemStyleObject = {
  sm: {
    button: {
      borderRadius: 8,
      pl: 2,
      pr: 2,
      py: 1.5,
    },
    icon: {
      width: 12,
    },
  },
  md: {
    button: {
      borderRadius: 8,
      pl: 3,
      pr: 3,
      py: 2.5,
    },
    icon: {
      width: 16,
    },
  },
  lg: {
    button: {
      borderRadius: 8,
      pl: 2.5,
      pr: 2.5,
      py: 2.5,
    },
    icon: {
      width: 24,
    },
  },
};

const variants: SystemStyleObject = {
  black: {
    button: {
      bg: `black`,
      _hover: {
        bg: `white`,
      },
    },
    icon: {
      color: `white`,
      _hover: {
        color: `black`,
      },
    },
  },
  noBorders: {
    button: {
      bg: `transparent`,
      _hover: {
        bg: `transparent`,
      },
      pl: 0,
      pr: 0,
      py: 0,
    },
    icon: {
      color: `black`,
      width: 18,
    },
  },
  numberInput: {
    button: {
      borderRadius: 8,
      pl: 3,
      pr: 3,
      py: 1,
      height: 25,
      width: 7,
      _disabled: {
        bgColor: `transparent`,
      },
    },
    icon: {
      width: 14,
    },
  },
  carousel: {
    button: {
      bg: `black`,
      borderWidth: 2,
      borderColor: `black`,
      borderRadius: 999,
      _hover: {
        bg: `transparent`,
      },
    },
    icon: {
      color: `white`,
      _hover: {
        color: `black`,
      },
    },
  },
};

const defaultProps: SystemStyleObject = {
  size: `md`,
};

export const IconButton = {
  parts: [`button`, `icon`],
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
