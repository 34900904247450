import { cssVar } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import { get } from 'react-hook-form';
import { TinyColor } from '@ctrl/tinycolor';

const $startColor = cssVar(`skeleton-start-color`);
const $endColor = cssVar(`skeleton-end-color`);

const getColor = (theme: any, color: string, fallback?: string) => {
  const hex = get(theme, `colors.${color}`, color);
  const { isValid } = new TinyColor(hex);
  return isValid ? hex : fallback;
};

const baseStyle = (props: any) => {
  const defaultStartColor = mode(`gray.50`, `gray.800`)(props);
  const defaultEndColor = mode(`gray.100`, `gray.600`)(props);

  const {
    startColor = defaultStartColor,
    endColor = defaultEndColor,
    theme,
  } = props;

  const start = getColor(theme, startColor);
  const end = getColor(theme, endColor);

  return {
    [$startColor.variable]: start,
    [$endColor.variable]: end,
    opacity: 0.7,
    borderRadius: `2px`,
    borderColor: start,
    background: end,
  };
};

export const Skeleton = {
  baseStyle,
};
