import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {};

const sizes: SystemStyleObject = {
  sm: {
    label: {
      fontSize: `text.5`,
      fontWeight: `semibold`,
    },
    icon: {
      width: 3.5,
    },
  },
  md: {
    label: {
      fontSize: `text.4`,
      fontWeight: `semibold`,
    },
    icon: {
      width: 4,
    },
  },
  lg: {
    label: {
      fontSize: `text.3`,
      fontWeight: `semibold`,
    },
    icon: {
      width: 4,
    },
  },
};

const variants: SystemStyleObject = {
  default: {
    container: {
      bg: `transparent`,
      px: 0,
      alignItems: `center`,
    },
    label: {
      h: 4,
    },
  },
};

const defaultProps: SystemStyleObject = {
  variant: `default`,
  size: `md`,
};

export const Tag = {
  parts: [`container`, `label`, `icon`],
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
