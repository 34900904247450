import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {};

const variants: SystemStyleObject = {
  solid: {
    bg: `black`,
    color: `white`,
    _hover: {
      bg: `black`,
      color: `white`,
      _disabled: {
        bg: `black`,
      },
    },
  },
  menu: {
    bg: `white`,
    color: `black`,
    h: `auto`,
    px: 5,
    py: 3,
  },
  cookies: {
    bg: `black`,
    color: `white`,
    h: `auto`,
    px: 5,
    py: 3,
  },
};

const sizes: SystemStyleObject = {
  xs: {
    borderRadius: `xl`,
    h: 10,
    px: 3,
  },
  sm: {
    borderRadius: `xl`,
    h: 14,
    px: 5,
  },
  md: {
    borderRadius: `xl`,
    h: 16,
    px: 10,
  },
};

const defaultProps: SystemStyleObject = {};

export const Button = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
