import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {};

const variants: SystemStyleObject = {
  quantity: {
    root: {
      maxW: `110px`,
    },
    field: {
      bg: `transparent`,
      fontSize: `20px`,
      fontWeight: `semibold`,
      h: 16,
      pl: 0,
      pr: 23,
      textAlign: `center`,
    },
  },
};

const sizes: SystemStyleObject = {};

const defaultProps: SystemStyleObject = {
  variant: `quantity`,
};

export const NumberInput = {
  parts: [`root`, `field`, `stepperGroup`, `stepper`],
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
