import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  fontWeight: `semibold`,
  textTransform: `none`,
};

const variants: SystemStyleObject = {
  black: {
    bg: `black`,
    color: `white`,
  },
  gray: {
    bg: `gray.200`,
    color: `black`,
  },
  teal: {
    bg: `#A770EF`,
    color: `black`,
  },
};

const sizes: SystemStyleObject = {
  xs: {
    borderRadius: `lg`,
    px: 2.5,
    py: 1,
  },
  sm: {
    borderRadius: `lg`,
    px: 4,
    py: 1.5,
  },
  md: {
    borderRadius: `xl`,
    px: 4,
    py: 2,
  },
  lg: {
    borderRadius: `xl`,
    h: 10,
    px: 5,
    display: `inline-flex`,
    alignItems: `center`,
  },
};

const defaultProps: SystemStyleObject = {
  variant: `black`,
  size: `md`,
};

export const Badge = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
