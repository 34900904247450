import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  _active: {
    bg: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
    bgClip: `text`,
    color: `transparent`,
    textDecoration: `none`,
  },
  _hover: {
    textDecoration: `none`,
  },
};

const variants: SystemStyleObject = {
  default: {},
  nav: {
    color: `black`,
    fontFamily: `heading`,
    fontSize: [`mobile.h3`, null, `text.3`],
    fontWeight: `600`,
    textAlign: `center`,
    transition: `0s`,
    _hover: {
      bg: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
      bgClip: `text`,
      color: `transparent`,
      textDecoration: `none`,
    },
    _active: {
      bg: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
      bgClip: `text`,
      color: `transparent`,
      textDecoration: `none`,
    },
  },
  footer: {
    color: `gray.500`,
    fontFamily: `body`,
    fontSize: `text.4`,
    fontWeight: `medium`,
    transition: `0s`,
    _hover: {
      bg: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
      bgClip: `text`,
      color: `transparent`,
      textDecoration: `none`,
    },
  },
  legal: {
    color: `gray.400`,
    fontFamily: `body`,
    fontSize: `text.4`,
    fontWeight: `medium`,
    textAlign: `center`,
    transition: `0s`,
    _hover: {
      bg: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
      bgClip: `text`,
      color: `transparent`,
      textDecoration: `none`,
    },
  },
  common: {
    color: `black`,
    transition: `0s`,
    _hover: {
      bg: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
      bgClip: `text`,
      color: `transparent`,
      textDecoration: `none`,
    },
  },
};

const sizes: SystemStyleObject = {};

const defaultProps: SystemStyleObject = {
  variant: `default`,
};

export const Link = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
