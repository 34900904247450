import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  button: {
    py: 8,
    textAlign: `left`,
  },
};

const variants: SystemStyleObject = {};

const sizes: SystemStyleObject = {};

const defaultProps: SystemStyleObject = {};

export const Accordion = {
  parts: [`container`, `item`, `button`, `panel`],
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
