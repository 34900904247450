import { colors, typography } from '@/theme/foundations';

import {
  Accordion,
  Badge,
  Button,
  Checkbox,
  Heading,
  IconButton,
  Input,
  Link,
  Menu,
  NumberInput,
  Select,
  Skeleton,
  Tag,
  Text,
} from '@/theme/components';

export const theme = {
  styles: {
    global: {
      ul: {
        listStyle: `none`,
      },
    },
  },

  colors,
  ...typography,
  components: {
    Accordion,
    Badge,
    Button,
    Checkbox,
    Heading,
    IconButton,
    Input,
    Link,
    Menu,
    NumberInput,
    Select,
    Skeleton,
    Tag,
    Text,
  },
};
