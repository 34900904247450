import { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  isTrue: boolean;
}

export function Conditional(props: Props): JSX.Element {
  const { children, isTrue } = props;

  return isTrue ? <>{children}</> : <></>;
}
