export const colors = {
  teal: {
    75: `hsl(168, 100%, 90%)`,
  },
  gray: {
    75: `#F1F5F9`,
  },
  grad1: `linear-gradient(90deg, #A770EF 0%, #CF8BF3 50%, #FDB99B 100%)`,
  grad2: `linear-gradient(135deg, #FFA8A8 0%, #FCFF00 100%)`,
  grad3: `linear-gradient(90deg, #FF6E7F 0%, #BFE9FF 100%)`,
  grad4: `linear-gradient(135deg, #F97794 0%, #623AA2 100%)`,
  grad5: `linear-gradient(135deg, #FD6585 0%, #2B285F 99.62%)`,
};
