import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  field: {
    cursor: `pointer`,
    border: 0,
    borderRadius: `xl`,
    paddingLeft: 6,
  },
  icon: {
    color: `gray.300`,
  },
};

const sizes: SystemStyleObject = {
  md: {
    field: {
      h: 16,
    },
  },
};

const defaultProps: SystemStyleObject = {
  size: `md`,
};

export const Select = {
  parts: [`field`, `icon`],
  baseStyle,
  sizes,
  defaultProps,
};
