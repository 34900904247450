import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  container: {
    display: `flex`,
    alignItems: `center`,
  },
  control: {
    mt: 1,
  },
  label: {
    color: `gray.500`,
    cursor: `pointer`,
  },
};

const defaultProps: SystemStyleObject = {};

export const Checkbox = {
  parts: [`container`, `control`, `label`, `icon`],
  baseStyle,
  defaultProps,
};
