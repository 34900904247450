import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  button: {
    bg: `white`,
    borderRadius: 12,
    px: 5,
    py: 3,
    '& > span': {
      display: `flex`,
      alignItems: `center`,
      '& > div': {
        mr: 3,
      },
    },
  },
};

const variants: SystemStyleObject = {};

const sizes: SystemStyleObject = {};

const defaultProps: SystemStyleObject = {};

export const Menu = {
  parts: [`button`, `list`, `item`],
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
