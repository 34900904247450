import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {};

const sizes: SystemStyleObject = {
  md: {
    field: {
      h: 16,
    },
  },
};

const variants: SystemStyleObject = {
  search: {
    field: {
      bg: [`gray.100`, null, `white`],
      border: 0,
      borderRadius: `xl`,
      height: 16,
      minWidth: [null, null, 400],
      paddingLeft: 6,
      _placeholder: {
        color: `gray.300`,
      },
    },
  },
};

const defaultProps: SystemStyleObject = {};

export const Input = {
  parts: [`addon`, `field`, `element`],
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
